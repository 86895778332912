@import "../themes/default";

@import "@material/card/mdc-card";
@import "@material/button/mdc-button";
@import "@material/list/mdc-list";
@import "@material/layout-grid/mdc-layout-grid";
@import "@material/menu-surface/mdc-menu-surface";
@import "@material/menu/mdc-menu";

.mdc {
  &-card{
    transition: all 0.3s ease-in-out;

    &.elevated:hover {
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }

    &__content {
      padding: 0 16px;
      flex-grow: 1;
      text-align: justify;

      & > img {
        float: left;
        height: 70px;
        margin: 0 15px 15px 0;
      }

      &.no-content .material-icons {
        position: relative;
        top: 5px;
      }
    }
  }

  &-dialog {
    &__surface {
      overflow: visible;
    }
    &__preview {
      padding: 10px;
      overflow: hidden;
      overflow-x: scroll;
      border: 1px solid #ddd;
      white-space: nowrap;
      min-height: 300px;
      & a {
        outline : none;
        margin: 0 15px 0 0;
      }
      & img {
        max-height: 260px;
      }
    }
    .mdc-tab {
      font-size: 11px;
      letter-spacing: initial;
    }
  }

  &-layout-grid {
    padding-top: 0;
  }

  @media (min-width: 840px) {
    &-layout-grid {
      padding: 0;
    }
  }
}

@media (min-width: 840px) {
  .main-content, .mdc-top-app-bar__row {
    width: 740px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1092px) {
  .main-content, .mdc-top-app-bar__row {
    width: 992px;
  }
}

@media (min-width: 1300px) {
  .main-content, .mdc-top-app-bar__row {
    width: 1200px;
  }
}
