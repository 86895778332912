* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

html {
  touch-action: manipulation;
}

body {
  background: #f5f5f5;
}

body, #root {
  display: flex;
  // height: 100vh;
  margin: 0; // Removes top gap between top app bar and viewport
  width: 100%;
}

.main-content, .mdc-top-app-bar__row {
  width: 100%;
}

.main-content {
  margin: 74px 0 30px 0;
  @media (max-width: 600px) {
    margin: 66px 0 30px 0;
  }
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}
