.mdc-dialog{
  &__title {
    .mdc-button__icon {
      float: right;
      cursor: pointer;
      position: relative;
      margin: 0 0 -10px 0;
      padding: 20px 0 15px 0;
    }
    input {
      width: 100%;
      height: 59px;
      font-size: 20px;
      margin: 0;
      padding: 0 25px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
