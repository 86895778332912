@import "../themes/default";

@import "@material/tab-bar/mdc-tab-bar";
@import "@material/tab-scroller/mdc-tab-scroller";
@import "@material/tab-indicator/mdc-tab-indicator";
@import "@material/tab/mdc-tab";

.mdc-tab-bar {
  margin: 0 0 15px 0;
}

